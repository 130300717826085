<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <!-- 설비 목록 -->
        <c-table
          ref="table"
          title="LBL0001726"
          tableId="equipmentTable"
          :columns="grid.columns"
          :data="grid.data"
          @linkClick="linkClick"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
            </q-btn-group>
          </template>
        </c-table>
        <c-dialog :param="popupOptions"></c-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'equipment-manager',
  data() {
    return {
      searchParam: {
        plantCd: null,
      },
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      grid: {
        columns: [
          {
            fix: true,
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width: 100px',
            sortable: true,
          },
          {
            fix: true,
            name: 'equipmentTypeName',
            field: 'equipmentTypeName',
            label: '설비유형',
            align: 'center',
            style: 'width: 150px',
            sortable: true,
          },
          {
            fix: true,
            name: 'equipmentCd',
            field: 'equipmentCd',
            label: '설비코드일련번호',
            align: 'center',
            type: 'link',
            style: 'width: 120px',
            sortable: true,
          },
          {
            fix: true,
            name: 'equipmentName',
            field: 'equipmentName',
            label: '설비명',
            align: 'left',
            style: 'width: 250px',
            sortable: true,
          },
          {
            name: 'maker',
            field: 'maker',
            label: '메이커',
            align: 'center',
            style: 'width: 150px',
            sortable: true,
          },
          {
            name: 'model',
            field: 'model',
            label: '모델',
            align: 'center',
            style: 'width: 150px',
            sortable: true,
          },
          {
            name: 'spec',
            field: 'spec',
            label: '규격',
            align: 'center',
            style: 'width: 150px',
            sortable: true,
          },
          {
            name: 'buycustcd',
            field: 'buycustcd',
            label: '도입처',
            align: 'center',
            style: 'width: 150px',
            sortable: true,
          },
          {
            name: 'ascustcd',
            field: 'ascustcd',
            label: 'AS업체',
            align: 'center',
            style: 'width: 150px',
            sortable: true,
          },
          {
            name: 'buydate',
            field: 'buydate',
            label: '구입일자',
            align: 'center',
            style: 'width: 120px',
            sortable: true,
          },
          {
            name: 'setdate',
            field: 'setdate',
            label: '설치일자',
            align: 'center',
            style: 'width: 120px',
            sortable: true,
          },
          {
            name: 'enddate',
            field: 'enddate',
            label: '폐기일자',
            align: 'center',
            style: 'width: 120px',
            sortable: true,
          },
          {
            name: 'epower',
            field: 'epower',
            label: '전력용량(KW)',
            align: 'center',
            style: 'width: 100px',
            sortable: true,
          },
          {
            name: 'statusnm',
            field: 'statusnm',
            label: '설비상태',
            align: 'center',
            style: 'width: 80px',
            sortable: true,
          },
          {
            name: 'testyn',
            field: 'testyn',
            label: '법정검사여부',
            align: 'center',
            style: 'width: 80px',
            sortable: true,
          },
          {
            name: 'bgmnyn',
            field: 'bgmnyn',
            label: '비가동관리여부',
            align: 'center',
            style: 'width: 80px',
            sortable: true,
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // api scope
      this.listUrl = selectConfig.mdm.equipment.list.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row) {
      this.openPop(row);
    },
    openPop(row) {
      this.popupOptions.title = 'LBL0001728'; // 설비 상세
      this.popupOptions.param = {
        equipmentCd: row ? row.equipmentCd : '',
      };
      this.popupOptions.target = () => import(`${'./equipmentDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>
